<template>
  <div class="container">
    <div class="header">
      <Header />
    </div>
    <div class="content">
      <div class="title">
        <p>Dashboard</p>
        <div class="btns">
          <button>Upload file</button>
          <button>Create folder</button>
        </div>
      </div>
      <div class="table">
        <table>
          <thead>
            <td>Name</td>
            <td>Last edited</td>
            <td>Members</td>
            <td></td>
          </thead>
          <tr v-for="(item, key) in data" :key="key" >
            <td><img src="../assets/images/folder.png" style="margin-right: 50px;"/>{{ item.name }}</td>
            <td>{{ item.date }}</td>
            <td v-if="item.members == 'Only you'" class="copy-link"><span>{{ item.members }}</span></td>
            <td v-else class="copy-link"><span>{{ item.members }}</span></td>
            <td><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" style="width: -webkit-fill-available; cursor: pointer" class="bi bi-three-dots" viewBox="0 0 16 16">
              <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
            </svg></td>
          </tr>
        </table>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "dashboard-page",
  components: {
    Header, Footer
  },
  data(){
    return {
      data: [
        {
          name: 'Accounting',
          date: '21/02/2020 at 10:12 AM',
          members: '3 members',
        },
        {
          name: 'Accounting',
          date: '21/02/2020 at 10:12 AM',
          members: 'Only you',
        },
        {
          name: 'Accounting',
          date: '21/02/2020 at 10:12 AM',
          members: '3 members',
        },
        {
          name: 'Accounting',
          date: '21/02/2020 at 10:12 AM',
          members: 'Only you',
        },
        {
          name: 'Accounting',
          date: '21/02/2020 at 10:12 AM',
          members: '4 members',
        },
        {
          name: 'Accounting',
          date: '21/02/2020 at 10:12 AM',
          members: 'Only you',
        },
        {
          name: 'Accounting',
          date: '21/02/2020 at 10:12 AM',
          members: '4 members',
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.copy-link:hover span {
  display: none
}

.copy-link:hover:before {
  content: "Copy link!";
  position: absolute;
  background: rgba(0, 169, 157, 0.38);
  border-radius: 6px;
  padding: 10px 25px;
  top: 20px;
  left: 0;
  cursor: pointer;
}
.container{
  .header{
    background-image: url("../assets/images/bg.png");
    min-height: 230px;
    background-repeat: no-repeat;
    background-position: bottom;
    position: relative;
  }
  .content{
    margin: 65px 240px 65px 240px;
    padding: 80px 120px;
    background: #FFFFFF;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.2);
    .title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
      p{
        margin: 0;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        color: #000000;
      }
      .btns{
        button{
          &:nth-child(1){
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
            line-height: 19px;
            background-color: transparent;
            border: none;
            outline: none;
            color: #008EA8;
          }
          &:nth-child(2){
            background: #009BA2;
            border-radius: 60px;
            padding: 22px 55px;
            cursor: pointer;
            border:none;
            outline: none;
            margin-left: 50px;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
          }
        }
      }
    }
    .table{
      table{
        width: 100%;
        text-align: left;
        border-spacing: 0 1em;
        thead{
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.5);
          border: 1px solid black;
          td{
            padding:0 45px;
          }
        }
        tr{
          background: #FFFFFF;
          border-radius: 60px;
          outline: rgba(0, 0, 0, 0.05) solid .5px;
          margin-top: 10px;
          border-spacing: 5px;
          td{
            padding: 20px 45px;
            position: relative;
            &:first-child{
              display: flex;
              align-items: center;

            }
            &:nth-child(3){
              padding: 10px 25px;
            }
          }
        }
      }
    }
  }
}

</style>
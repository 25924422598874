<template>
    <div>
        <div class="header">
            <div class="logo">
                <router-link to="/">Logo</router-link>
            </div>
            <div class="menu">
                <div class="menu-items">
                    <div class="menu-item">
                        <p>Products
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" style="margin-left: 6px;"  fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </p>
                        <div class="dropdown-items">
                            <p>12</p>
                            <p>2</p>
                            <p>3</p>
                        </div>
                    </div>
                    <div class="menu-item">
                        <p>Solutions
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" style="margin-left: 6px;"  fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </p>
                        <div class="dropdown-items">
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                        </div>
                    </div>
                    <div class="menu-item">
                        <p>Resources
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" style="margin-left: 6px;" height="12" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </p>
                        <div class="dropdown-items">
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                        </div>
                    </div>
                    <div class="menu-item">Customers</div>
                    <div class="menu-item">Pricing</div>
                    <div class="menu-item">Contacts</div>
                </div>
            </div>
            <div class="auth" v-if="!hasToken">
                <div class="login">
                    <img src="../assets/images/users.png" />
                  <button ><router-link to="/login">Log in</router-link></button>
                </div>
                <div class="sign-up">
                  <button><router-link to="/register">Sign up</router-link></button>
                </div>
            </div>
            <div class="user-profile" v-else>
            <p class="name">{{this.user.email}}
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" style="margin-left: 6px;"  fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </p>
            <div class="user-dropdown-items">
              <p><router-link to="/dashboard">Dashboard</router-link></p>
              <p>Settings</p>
              <p @click="logout">Logout</p>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "header-part",
    data(){
        return {
          hasToken: false,
          mix_uri: process.env.VUE_APP_API_URL,
          user: []
        }
    },
  methods: {
    logout(){
      axios.post(this.mix_uri + '/auth/logout').then((res) => {
        console.log(res)
        if(res.status === 200 || res.data.message){
          localStorage.removeItem('access_token')
          this.$router.go();
        }
      })
    }
  },
  mounted() {
    this.hasToken = !!localStorage.getItem('access_token');
    console.log(this.hasToken)
    axios.get(process.env.VUE_APP_API_URL + '/auth/user-profile', {
          headers:
              {"Authorization": `Bearer ${localStorage.getItem('access_token')}`}
        }).then((res) => {
          if(res.status === 200){
            if(res.data.status){
              localStorage.removeItem('access_token')
            }
            this.user = res.data
          }
    })
  }
}
</script>

<style lang="scss" scoped>
button{
  cursor: pointer;
}
.header{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding-top: 40px;
    justify-content:space-between;
    .logo{
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 50px;
        color: #FFFFFF;
        a{
          cursor: pointer;
          color: #FFFFFF;
          text-decoration: none;
        }
    }
    .menu{
        .menu-items{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .menu-item{
                position: relative;
                cursor: pointer;
                font-family: 'Lato', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: rgba(255, 255, 255, 0.9);
                margin-right: 55px;
                display: flex;
                align-items: flex-end;
                svg{
                    //padding-top: 3px;
                }
                &:hover .dropdown-items{
                    display: block;
                }
            .dropdown-items{
                display: none;
                background-color: #fff;
                text-align: left;
                border-radius: 10px;
                padding: 10px;
              color: black;
                z-index: 10;
                width: 160px;
                min-height: 50px;
                position: absolute;
                top: 48px;
                left: -40px;
            }
        }
        }
    }
    .auth{
        display: flex;
        align-items:center;
        .login{
            display: flex;
            align-items: center;
            button{
                background-color: transparent;
                border: none;
                outline: none;
                font-family: 'Lato', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
              a{
                color: #FFFFFF;
                text-decoration: none;
              }
            }
        }
        .sign-up{
            button{
                padding: 11px 29.5px;
                background-color: #FFFFFF;
                border-radius: 70px;
                border:none;
                outline: none;
                margin-left: 30px;
                font-family: 'Lato', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #00A99D;
                a{
                  color: #00A99D;
                  text-decoration: none;
                }

            }
        }
    }
    .user-profile{
      position: relative;
      &:hover .user-dropdown-items{
        display: block;
        overflow: visible;
      }
      .name{
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        cursor: pointer;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.8);

      }
      .user-dropdown-items{
        display: none;
        overflow: hidden;
        position: absolute;
        background-color: #fff;
        width: 100%;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        text-align: left;
        padding: 18px 10px;
        & > *:not(:last-child){
          margin-bottom: 20px;
        }
        & > *{
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.7);
          cursor: pointer;
          a{
            text-decoration: none;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.7);
            cursor: pointer;
          }
        }
      }
    }
    .logout{
        display: flex;
        align-content: center;
        justify-content:center;
        button{
          background-color: transparent;
          border: none;
          outline: none;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 22px;
          color: #FFFFFF;
          text-decoration: none;
          padding: 0px 15px;
          border-radius: 10px;
          margin: 0;
        }
    }
}
</style>

<template>
    <div class="container">
        <div class="header">
            <Header />

        </div>
      <div class="register">
        <div class="title">
          <p>Sign Up</p>
        </div>
        <div class="form">
          <div class="title">
            <p>Create a free account</p>
          </div>
          <div class="email">
            <label for="email">Email</label><br />
            <input type="email" v-model="email" id="email">
          </div>
          <div class="password">
            <label for="password">Password</label><br />
            <input type="password" v-model="password" id="password">
          </div>
          <div class="password_confirm">
            <label for="password_confirmation">Password Confirmation</label><br />
            <input type="password" v-model="password_confirmation" id="password_confirmation">
          </div>
          <div class="error" v-show="hasError">
            <p>Authentication error! Try again</p>
          </div>
          <div class="btn">
            <button @click="sendData">SUBMIT</button>
          </div>
        </div>
      </div>

        <div class="footer">
            <Footer />
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

export default {
    name: "register-page",
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
      return {
          email: '',
          password: '',
          password_confirmation: '',
          mix_uri: process.env.VUE_APP_API_URL,
          hasError: false,
      }
    },
    validations() {
        return {
            email: {required, email},
            password: {required, minLength: minLength(3)},
            password_confirmation: {required, minLength: minLength(3)},
        }
    },
    methods: {
        sendData(){
            this.v$.$validate()
            if(!this.v$.$error){
                axios.post(this.mix_uri + '/auth/register', {
                  email: this.email,
                  password: this.password,
                  password_confirmation: this.password_confirmation,
                }).then((res) => {
                  if(res.status === 201){
                    this.$router.push('/login')
                  }else{
                    this.hasError = true
                  }
                })
            }else{
                alert('Invalid data!')
            }
        }
    },
    components: {
        Header, Footer

    }
}
</script>

<style scoped lang="scss">
.container{
    display: flex;
    flex-direction: column;
    .header{
        background-image: url("../assets/images/bg.png");
        min-height: 230px;
        background-repeat: no-repeat;
        background-position: bottom;
        position: relative;
        //padding: 20px 0;
    }

  .register{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    .title{
      margin-bottom: 30px;
      p{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 42px;
        line-height: 50px;
        margin: 0;
        color: black;
        text-align: center;
      }
    }
    .form{
      background-color: #fff;
      box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      margin-bottom: 100px;
      padding: 30px 82px;
      border-radius: 20px;
      input{
        min-width: 340px !important;
      }
      .title{
        p{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: black;
          margin: 0;

        }
      }
      .email{
        margin-bottom: 20px;
        label{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          padding-left: 5px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.6);
        }
        input{
          background: rgba(255, 255, 255, 0.95);
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 60px;
          width: 100%;
          margin-top: 5px;
          padding: 10px;
        }
      }
      .password{
        margin-bottom: 20px;
        label{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          padding-left: 5px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.6);
        }
        input{
          background: rgba(255, 255, 255, 0.95);
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 60px;
          width: 100%;
          padding: 10px;
          margin-top: 5px;
        }
      }
      .password_confirm{
        margin-bottom: 20px;
        label{
          font-family: 'Lato';
          font-style: normal;
          padding-left: 5px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.6);
        }
        input{
          background: rgba(255, 255, 255, 0.95);
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 60px;
          width: 100%;
          margin-top: 5px;
          padding: 10px;
        }
      }
      .error{
        color: red;
      }
      .btn{
        button{
          background: linear-gradient(95.51deg, #008BA9 -40.96%, #00A99D 54.03%);
          border-radius: 60px;
          padding: 17px 122px;
          width: 100%;
          border:none;
          outline: none;
          cursor: pointer;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          text-transform: uppercase;
          color: #FFFFFF;
        }
      }
    }
  }
}

</style>

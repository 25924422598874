<template>
    <div class="container">
        <div class="header">
            <Header />

        </div>

      <div class="register">
        <div class="title">
          <p>Sign In</p>
        </div>
        <div class="form">
          <div class="title">
            <p>Sign in to your account</p>
          </div>
          <div class="email">
            <label for="email">Email</label><br />
            <input type="email" v-model="email" id="email">
          </div>
          <div class="password">
            <label for="password">Password</label><br />
            <input type="password" v-model="password" id="password">
          </div>
          <div class="forgot">
            <a href="/">Forgot your password?</a>
          </div>
          <div class="btn">
            <button @click="auth">Sign in</button>
          </div>
          <div class="signup">
            <span>Don't have an account? </span><a href="/register" class="link">Sign up!</a>
          </div>
        </div>
      </div>
        <div class="footer">
            <Footer />
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
export default {
    name: "login-page",
    setup () {
      return { v$: useVuelidate() }
    },
    data(){
        return {
            email: '',
            password: '',
            mix_uri: process.env.VUE_APP_API_URL
        }
    },
    validations() {
      return {
        email: {required, email},
        password: {required, minLength: minLength(3)},
      }
    },
    methods: {
      auth(){
        this.v$.$validate()
        if(!this.v$.$error){
          axios.post(this.mix_uri + '/auth/login', {
            email: this.email,
            password: this.password
          }).then((response) => {
            if(response.status === 200){
              localStorage.setItem('access_token', response.data.access_token)
                this.$router.push('/')
            }
          })
            .catch(() => {
              alert('Invalid data')
            })
        }
      }
    },
    components: {
        Header, Footer

    }
}
</script>

<style scoped lang="scss">
.link{
    color: #009EA1;
}
.container{
    display: flex;
    flex-direction: column;
    .header{
        background-image: url("../assets/images/bg.png");
        min-height: 230px;
        background-repeat: no-repeat;
        background-position: bottom;
        position: relative;
        //padding: 20px 0;
    }

  .register{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    .title{
      margin-bottom: 30px;
      p{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 42px;
        line-height: 50px;
        color: black;
        margin-bottom: 0;
        margin-top: 0;
        text-align: center;
      }
    }
    .form{
      box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      background-color: #fff;
      padding: 30px 82px;
      box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.2);
      margin-bottom: 100px;
      input{
        min-width: 340px !important;
      }
      .title{
        p{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: #000000;
          margin: 0;

        }
      }
      .email{
        margin-bottom: 20px;
        label{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          padding-left: 5px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.6);
        }
        input{
          background: rgba(255, 255, 255, 0.95);
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 60px;
          width: 100%;
          margin-top: 5px;
          padding: 10px;
        }
      }
      .password{
        margin-bottom: 10px;
        label{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          padding-left: 5px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.6);
        }
        input{
          background: rgba(255, 255, 255, 0.95);
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 60px;
          width: 100%;
          padding: 10px;
          margin-top: 5px;
        }
      }
      .forgot{
        padding-bottom: 10px;
        a{
          padding-left: 10px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          text-decoration: none;
          line-height: 19px;
          cursor: pointer;
          color: #00A99D;
        }
      }
      .btn{
        button{
          background: linear-gradient(95.51deg, #008BA9 -40.96%, #00A99D 54.03%);
          border-radius: 60px;
          padding: 17px 122px;
          width: 100%;
          border:none;
          outline: none;
          font-family: 'Lato';
          cursor: pointer;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          text-transform: uppercase;
          color: #FFFFFF;
        }
      }
      .signup{
        text-align: center;
        margin-top: 20px;
        span{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

</style>

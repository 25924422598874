<template>
    <div class="footer">
        <div class="logo">
            <p>Logo</p>
            <div class="social">
                <img src="../assets/images/instagram.png" alt="insagram"/>
                <img src="../assets/images/fb.png" alt="facebook"/>
                <img src="../assets/images/linkedin.png" alt="Linkedin"/>
                <img src="../assets/images/twiter.png" alt="twitter"/>
            </div>
        </div>
        <div class="resource">
            <h3>Resources</h3>
            <div class="menu">
                <h4>Help</h4>
                <h4>Events</h4>
                <h4>Live Support</h4>
                <h4>Open Sources</h4>
                <h4>Documentation</h4>
            </div>
        </div>
        <div class="company">
            <h3>Company</h3>
            <div class="menu">
                <h4>About Us</h4>
                <h4>Careers</h4>
                <h4>Customers</h4>
                <h4>Community</h4>
                <h4>Our Team</h4>
            </div>
        </div>
        <div class="location">
            <h3>Location</h3>
            <div class="menu">
                <h4>121 King Melbourne</h4>
                <h4>Australia 3000</h4>
                <h4>+61 2 8376 6284</h4>
                <h4>mail@example.com</h4>
                <h4>www.yourdomain.com</h4>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "Footer"
}
</script>

<style lang="scss" scoped>
.footer{
    display: flex;
    justify-content: space-between;
    padding: 204px;
    background-image: url("../assets/images/members-bg.png");
    .logo{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 50px;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p{
            margin: 0;
        }
        img:not(:last-child){
            margin-right: 10px;
        }
    }
    .resource, .company, .location{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .resource h3, .company h3, .location h3{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        line-height: 24px;
        color: #FFFFFF;
    }
    .resource h4, .company h4, .location h4{
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 200%;
        margin: 0;

        color: rgba(255, 255, 255, 0.9);

    }
}
</style>

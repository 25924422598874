import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource';
import axios from "axios";
Vue.use(VueResource)
import VueRouter from "vue-router";
Vue.use(VueRouter)
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)

//components
import Home from "@/Home";
import Register from "@/pages/Register";
import Login from "@/pages/Login";
import Dashboard from "@/pages/Dashboard";

//middleware
import auth from './middleware/auth'

const routes = [
  {
    path: '/',
    component: Home,
    name: 'home'
  },
  {
    path: '/register',
    component: Register,
    name: 'register'
  },
  {
    path: '/login',
    component: Login,
    name: 'login'
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
    meta: {
      middleware: auth
    }
  },
];

Vue.http.options.root = 'http://127.0.0.1:8000/api/'
// Vue.config.productionTip = false
axios.defaults.headers.common= {'Authorization': 'Bearer' + localStorage.getItem('access_token')}

const router = new VueRouter({
  routes : routes,
  mode: 'history'
})

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')

<template>
    <div>
        <div class="header">
            <Header />
            <div class="content">
                <div class="text">
                    <div class="title">
                        <p>Simplify how you work</p>
                    </div>
                    <div class="subtitle">
                        <p>Secure collaboration with anyone, anywhere, on any device</p>
                    </div>
                    <div class="btns">
                        <button>Get started</button>
                        <button>Contact us</button>
                    </div>
                </div>
                <div class="img">
<!--                    <img src="./assets/images/social.png" />-->
                </div>
            </div>
        </div>
        <div class="organizations">
            <div class="title">
                <p>Trusted by the world's leading organizations</p>
            </div>
            <div class="photos">
                <img src="./assets/images/vox.png" />
                <img src="./assets/images/vevo.png" />
                <img src="./assets/images/konami.png" />
                <img src="./assets/images/redux.png" />
            </div>
        </div>
        <div class="app-info">
            <div class="integration">
                <div class="text">
                    <p>1,500+ app integrations</p>
                    <span>With theContent Cloud, you get a single, secure platform for all your content — no matter where it’s created, accessed, shared, or saved. More than 1,500 seamless integrations mean teams can work the way they want without sacrificing security or manageability.</span><br />
                    <button>Learn more </button>
                </div>
                <img src="./assets/images/app1.png" />
            </div>
            <div class="workflow">
                <img src="./assets/images/app2.png" />
                <div class="text">
                    <p>Simplified workflow</p>
                    <span>Manual, cumbersome processes waste hours each day. So we let anyone automate the repeatable workflows that are key to your business, like HR onboarding and contract management. Workflows move faster, and you focus on what matters most. It's a win-win.</span><br />
                    <button>Learn more</button>
                </div>
            </div>
        </div>
        <div class="phone-page">
            <div class="content">
                <p>Use Your Android or ios Device to Manage Everything</p>
                <span>Proactively syndicate open-source e-markets after low-risk high-yield synergy. Professionally simplify visionary technology.</span>
                <div class="list">
                    <div class="item">
                        <img src="./assets/images/brush.png" alt="brush"/>
                        <span>Intrinsicly aggregate cutting-edge internal or "organic" sources through pandemic manufactured products. Synergistically.</span>
                    </div>
                    <div class="item">
                        <img src="./assets/images/brush.png" alt="brush"/>
                        <span>Completely administrate empowered e-tailers after extensive experiences. Holisticly leverage existing quality networks.</span>
                    </div>
                    <div class="item">
                        <img src="./assets/images/brush.png" alt="brush"/>
                        <span>Intrinsicly incentivize functionalized metrics whereas go forward networks. Collaboratively fabricate clicks-and-mortar intellectual.</span>
                    </div>
                </div>

            </div>
        </div>
        <div class="tariff">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#00A99D" fill-opacity="0.1" d="M0,32L80,80C160,128,320,224,480,224C640,224,800,128,960,101.3C1120,75,1280,117,1360,138.7L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
            </svg>
            <div class="content">
                <div class="title">
                    <p>Affordable Pricing and Packages Choose your Best One</p>
                    <h6>Distinctively brand cutting-edge imperatives through synergistic infrastructures customize low-risk high-yield processes rather than user friendly.</h6>
                </div>
                <div class="cards">
                    <div class="card">
                        <h3>Basic</h3>
                        <h2>$100</h2>
                        <div class="include">
                            <div class="item" v-for="(item, key) in basic" :key="key" >
                                <img src="./assets/images/fi_check-circle.png" alt="check" />
                                <span>{{item}}</span>
                            </div>
                            <button>buy</button>
                        </div>
                    </div>
                    <div class="card card-big">
                        <h3>Standard</h3>
                        <h2>$200</h2>
                        <div class="include">
                            <div class="item" v-for="(item, key) in standard" :key="key" >
                                <img src="./assets/images/fi_check-circle.png" alt="check" />
                                <span>{{item}}</span>
                            </div>
                            <button>buy</button>
                        </div>
                    </div>
                    <div class="card">
                        <h3>Unlimited</h3>
                        <h2>$300</h2>
                        <div class="include">
                            <div class="item" v-for="(item, key) in unlimited" :key="key" >
                                <img src="./assets/images/fi_check-circle.png" alt="check" />
                                <span>{{item}}</span>
                            </div>
                            <button>buy</button>
                        </div>
                    </div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#00A99D" fill-opacity="0.1" d="M0,160L48,176C96,192,192,224,288,234.7C384,245,480,235,576,218.7C672,203,768,181,864,181.3C960,181,1056,203,1152,224C1248,245,1344,267,1392,277.3L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
            </svg>
        </div>
        <div class="platform">
            <div class="cards">
                <div class="card">
                    <div class="content">
                        <h5>Open platform</h5>
                        <h6>With open APIs, first-party SDKs, integrated developer tools, and rich documentation, you can customize and extend Box to suit your business needs.</h6><br />
                        <a href="/">Learn more</a>
                    </div>
                </div>
                <div class="card card-full">
                    <div class="content">
                        <h5>Content migration</h5>
                        <h6>Accelerate your move to the Content Cloud with Box Shuttle, our market-leading content migration tool.</h6><br />
                        <a href="/">Learn more</a>
                    </div>
                </div>
                <div class="card">
                    <h5>Admin controls</h5>
                    <h6>User management is simple with Box. Intelligent monitoring and reporting tools give you a bird’s-eye view of how content is being shared.</h6><br />
                    <a href="/">Learn more</a>
                </div>
            </div>
        </div>
        <div class="members">
            <div class="title">Team members</div>
            <div class="members-list">
                <div class="member">
                    <img src="./assets/images/girl.png" alt="girl"/>
                    <h3>Ann Simon</h3>
                    <h4>UI/UX Designer</h4>
                    <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>
                </div>
                <div class="member">
                    <img src="./assets/images/girl.png" alt="girl"/>
                    <h3>Ann Simon</h3>
                    <h4>UI/UX Designer</h4>
                    <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>
                </div>
                <div class="member">
                    <img src="./assets/images/girl.png" alt="girl"/>
                    <h3>Ann Simon</h3>
                    <h4>UI/UX Designer</h4>
                    <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>
                </div>
                <div class="member">
                    <img src="./assets/images/girl.png" alt="girl"/>
                    <h3>Ann Simon</h3>
                    <h4>UI/UX Designer</h4>
                    <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>
                </div>
            </div>
        </div>
        <div class="ready">
            <p>Ready to simplify how you work?</p>
            <div class="btns">
                <button>Get started</button>
                <button>Contact Us</button>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from './components/Footer';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "Home",
    components: {
        Header, Footer
    },
    data(){
        return {
            basic: ['Organization-wide collaboration', 'Upload files up to 5GB', 'Unlimited storage', 'Data loss protection', 'Box Relay', 'Box Canvas'],
            standard: ['Unlimited external collaborators', 'Upload files up to 15GB', 'Unlimited storage', 'Data loss protection', 'Advanced search filters', 'Box Canvas'],
            unlimited: ['Upload files up to 150GB', 'Box Shield', 'Box Governance', ' Data loss protection', 'Document watermarking', '2FA for external users']
        }
    }
}
</script>

<style lang="scss" scoped>
button{
  cursor: pointer;
}
    .header{
        background-image: url("assets/images/new.png");
        background-repeat: no-repeat;
        background-position: center;
        min-height: 904px;
        position: relative;
        //padding-top: 60px;
        .content{
            max-width: 1440px;
            width: 100%;
            margin: 0 auto;
            margin-top: 136px;
            .text{
                .title{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 72px;
                    line-height: 86px;
                    color: #FFFFFF;
                    margin-bottom: 15px;
                    max-width: 563px;
                    p{

                        margin: 0 !important;
                    }
                }
                .subtitle{
                    max-width: 563px;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 36px;
                    margin-bottom: 44px;
                    color: #FFFFFF;
                    p{
                        margin: 0 !important;
                    }
                }
                .btns{
                  text-align: left;
                    & button:nth-child(1){
                        background: #FFFFFF;
                        border-radius: 30px;
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        padding: 23px 60px;
                        border: none;
                        outline: none;
                        color: #00A0A1;
                        margin-right: 23px;
                    }
                    & button:nth-child(2){
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        border: 1px solid #FFFFFF;
                        border-radius: 30px;
                        padding: 23px 60px;
                        background-color: transparent;
                        color: #FFFFFF;
                    }
                }
            }
            .img{
                position: absolute;
            }
        }
    }
    .organizations{
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        text-align:center;
        .title{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 52px;
            line-height: 62px;

            color: #000000;

        }
        .photos{
            display: flex;
            justify-content: space-around;
            align-items: center;
            img{
                opacity: .4;
                cursor: pointer;
                &:hover{
                    opacity: 1;
                }
            }
        }
    }
    .app-info{
        padding-top: 130px;
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        .integration{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 131px;
            .text{
                width: 555px;
                p{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 52px;
                    line-height: 62px;
                    color: #000000;
                    margin-top: 0;
                }
                span{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.5);
                    margin-bottom: 30px !important;
                }
                button{
                    background: linear-gradient(97.11deg, #008BA9 13.13%, #00A99D 86.46%);
                    border-radius: 30px;
                    padding: 24px 64px;
                    border: none;
                    outline: none;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    cursor: pointer;
                    line-height: 22px;
                    margin-top: 30px;
                    color: #FFFFFF;
                }
            }
        }
        .workflow{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .text{
                text-align: right;
                width: 552px;
                p{

                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 52px;
                    line-height: 62px;
                    color: #000000;
                    margin-top: 0;
                    margin-bottom: 30px;
                }
                span{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: right;
                    color: rgba(0, 0, 0, 0.5);
                }
                button{
                    padding: 24px 64px;
                    margin-top: 30px;
                    background: linear-gradient(97.11deg, #008BA9 13.13%, #00A99D 86.46%);
                    border-radius: 30px;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    cursor: pointer;
                    border:none;
                    outline: none;
                    color: #FFFFFF;
                }
            }
        }
    }
    .phone-page{
        background-image: url("assets/images/bg-phone.png");
        padding-bottom: 1px;
        .content{
            max-width: 708px;
            width: 100%;
            margin-left: 232px;
            p{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 52px;
                line-height: 62px;
                color: #FFFFFF;
                padding-top: 230px;
            }
            span{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 36px;
                color: #FFFFFF;
            }
            .list{
                margin-bottom: 200px;
                .item{
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    img{
                        margin-right: 40px;
                    }
                    span{
                        font-family: 'Lato';
                        font-style: normal !important;
                        font-weight: 400 !important;
                        font-size: 20px !important;
                        line-height: 24px !important;
                        color: #FFFFFF;
                    }
                }
            }
        }

    }
    .tariff{
        .content{
            background-color: #E5F6F5;
            margin-top: -3px;
            .title{
                p{
                    margin: 0 auto;
                    padding: 0;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 52px;
                    line-height: 62px;
                    text-align: center;
                    color: #000000 !important;
                    width: 800px;
                }
                h6{
                    padding: 0;
                    margin: 0 auto;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 36px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.5) !important;
                    width:1100px !important;
                }
            }
            .cards{
                margin-top: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                .card{
                    width: 467px;
                    background-color: #fff;
                    border-radius: 10px;
                    padding: 50px 74px;
                    h3{
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        margin-top: 0;
                        line-height: 38px;
                        text-align: center;
                        margin-bottom: 15px;
                        color: rgba(0, 0, 0, 0.5);
                    }
                    h2{
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 800;
                        font-size: 42px;
                        line-height: 50px;
                        text-align: center;
                        margin-bottom: 15px;
                        margin-top: 0;
                        color: #000000;
                    }

                    .include{
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        .item{
                            display: flex;
                            align-items: center;
                            img{

                                margin-right: 13px;
                            }
                        }
                        button{
                            text-transform: uppercase;
                            padding: 21.5px 91.5px;
                            background: linear-gradient(283.26deg, #00A99D 12.49%, #008BA9 90.44%);
                            border-radius: 32.5px;
                            border:none;
                            outline: none;
                            cursor:pointer;
                            position: relative;
                            margin: 0 auto;
                            font-family: 'Lato';
                            font-style: normal;
                            font-weight: 800;
                            font-size: 18px;
                            line-height: 22px;
                            width: 221px;
                            text-align: center;
                            text-transform: uppercase;
                            color: #FFFFFF;
                        }
                        .item:not(:last-child){
                            margin-bottom: 20px;
                        }
                    }
                }
                .card-big{
                    padding: 104px 50px !important;
                }
                .card:not(:last-child){
                    margin-right: 20px;
                }
            }
        }
    }
    .platform{
        .cards{
            display: flex;
            justify-content: center;
            .card{
                width: 445px;
                padding: 209px 49.5px 184px 49.5px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 20px;
                text-align: center;
                h5{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 146%;
                    text-align: center;
                    color: #000000;
                    margin-top: 0;
                    margin-bottom: 12px;
                }
                h6{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 146%;
                    text-align: center;
                    color: #000000;
                    margin: 0;
                }
                a{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 146%;
                    text-align: center;
                    text-decoration: none;
                    color: #00A99D;
                }
            }
            .card:not(:last-child){
                margin-right: 42px;
            }
            .card-full{
                background-image: url("assets/images/card-bg.png");
                background-position: center;
                background-size: cover;
                //filter: blur(1px);
                //filter: contrast(50%);
                .content{
                    color:white !important;
                    h5{
                        color: #FFFFFF !important;
                    }
                    h6{
                        color:white !important;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
    .members{
        margin-top: 134px;
        background: url("assets/images/members-bg.png");
        padding-top: 200px;
        padding-bottom: 120px;
        .title{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 62px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 70px;
        }
        .members-list{
            display: flex;
            justify-content:center;
            align-items: center;
            max-width: 1440px;
            width: 100%;
            margin:  0 auto;
            .member{
                width:345px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img{
                    border-radius: 50%;
                    margin: 0 auto;
                    //width: 270px;
                    //height: 270px;
                }
                h3{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    margin: 30px 0 11px 0;
                    line-height: 36px;
                    text-align: center;
                    color: #FFFFFF;
                }
                h4{

                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 22px;
                    margin: 0 0 11px 0;
                    text-align: center;
                    line-height: 26px;
                    color: #FFFFFF;
                }
                h5{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    margin: 0;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
                }

            }
            .member:not(:last-child){
                margin-right: 94px;
            }
        }
    }
    .ready{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 134px 0 195px 0;
        p{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 62px;
            text-align: center;
            color: #000000;
        }
        .btns{
            button:nth-child(1){
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
                background: linear-gradient(97.11deg, #008BA9 13.13%, #00A99D 86.46%);
                border-radius: 30px;
                bordeR:none;
                outline: none;
                margin-right: 23px;
                cursor:pointer;
                padding: 23.5px 64px;
            }
            button:nth-child(2){
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                cursor:pointer;
                background-color: transparent;
                padding: 23.5px 64px;
                color: #009EA2;
                border: 1px solid #009AA3;
                border-radius: 30px;
            }
        }
    }
</style>
